@font-face {
    font-family: "boxing";
    src: url("./trias/boxing.otf");
  }
  @font-face {
    font-family: "Montserrat-Light";
    src: url("./trias/Montserrat-Light.otf");
  }
  @font-face {
    font-family: "Montserrat-Medium";
    src: url("./trias/Montserrat-Medium.otf");
  }
  @font-face {
    font-family: "Montserrat-SemiBold";
    src: url("./trias/Montserrat-SemiBold.otf");
  }
  @font-face {
    font-family: "MantouSans";
    src: url("./trias/MantouSans-Regular.ttf"),url('./trias/MantouSans-Regular.woff2');
}
