@import '@rainbow-me/rainbowkit/styles.css';
@import "react-toastify/dist/ReactToastify.css";
@import "./toast.scss";
*{
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
}
html {
  background-color:  #09090B;
}
//model
.ant-modal{
  width: fit-content !important;
  background-color: rgba(255, 255, 255, 0);
}
.ModelContainer .ant-modal-content{
  padding: 0 !important;
  background: none !important;
}
.ant-modal .ant-modal-content{
  padding: 0 !important;
  background: none !important;
}

.ant-drawer-right>.ant-drawer-content-wrapper{
  width: 788rem !important;
  box-shadow: none !important;
}
.ant-drawer .ant-drawer-body{
  padding: 0 !important;
  outline: none;

}
.ant-drawer .ant-drawer-content{
  background-color: rgba(255, 255, 255, 0);
}
.ant-drawer{
  outline: none;
}
.ant-drawer .ant-drawer-body{
  padding: 0 !important;
  outline: none;
}

.nav-link {
  padding: 0;
}

.nav-item a {
  padding: 0 .5rem;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.error {
  color: red;
  font-weight: bold;
}

$tile-height: 70px;
$tile-width: 70px;
$tile-gap: 10px;

.tiles {
  flex: 1;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, 70px);
  grid-template-rows: repeat(auto-fit, 70px);
  grid-gap: 10px;
  overflow: visible;

  .tile {
    display: flex;
    position: relative;

    @for $i from 1 through 8 {
      &.cols-#{$i} {
        grid-column: span $i;
        width: $i * $tile-width + ($i - 1) * $tile-gap;
      }

      &.rows-#{$i} {
        grid-row: span $i;
        height: $i * $tile-width + ($i - 1) * $tile-gap;
      }
    }

    &:hover {
      outline: rgba(29, 29, 29, 0.1) solid 4px;
    }

    .teaser {
      font-size: 12em;
      padding: 1em;
      background: #f8f9fa;
    }
  }
}

